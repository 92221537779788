// @ts-ignore
/* eslint-disable */
import { request } from '../sdk';

/** 聚合 GET /api/enum */
export async function getApiEnum(options?: { [key: string]: any }) {
  return request<platform.JsonResponse>(`./api/enum`, {
    method: 'GET',
    ...(options || {}),
  });
}
