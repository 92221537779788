/**
 *
 */
export default class Socket {
  conn: WebSocket | null;
  onOpen: () => any;
  onMessage: (e: any) => void;
  uid: number;
  topicId: number;

  init(uid: number, topicId: number, onOpen: () => any, onMessage: (e: any) => void) {
    this.onOpen = onOpen;
    this.onMessage = onMessage;
    this.uid = uid;
    this.topicId = topicId;
    this.close();
    this.reconnect();
  }

  /**
   *
   * @returns
   */
  async reconnect() {
    return new Promise<void>((s, f) => {
      if (!this.conn || this.conn.readyState === 3 || this.conn.readyState === 4) {
        this.conn?.close();
        this.conn = new WebSocket(
          `${window.location.protocol == "http:" ? "ws" : "wss"}://${window.location.host}/api/websocket/${this.uid}/${
            this.topicId
          }`
        );
        this.conn.onopen = () => {
          this.onOpen();
        };
        this.conn.onmessage = (e) => {
          console.log("websocket消息", e.data);
          try {
            const data = JSON.parse(e.data);
            if (data.error && data.error.code == "context_length_exceeded") {
              uni.showModal({ title: "话题内容过长,请重新创建话题", icon: "none", duration: 2000 });
              this.onMessage({ data: "[DONE]" });
              return;
            }
          } catch (e) {}
          this.onMessage(e);
        };
        this.conn.onclose = (e) => {
          console.error("websocket closed", e);
          setTimeout(() => {
            this.reconnect();
          }, 5000);
        };
      } else {
        s();
      }
    });
  }

  /**
   *
   * @param keyword
   */
  async send(keyword: any) {
    await this.reconnect();
    this.conn?.send(keyword);
  }

  /**
   *
   */
  close() {
    this.conn?.close();
    this.conn = null;
  }
}
