// @ts-ignore
/* eslint-disable */
import { request } from '../sdk';

/** 激活 GET /api/boss/proxy/setting/active/${param0} */
export async function getApiBossProxySettingActiveById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.getApiBossProxySettingActiveByIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<platform.JsonResponse>(`./api/boss/proxy/setting/active/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 新增 POST /api/boss/proxy/setting/add */
export async function postApiBossProxySettingAdd(
  body: platform.ProxySettingAddDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponselong>(`./api/boss/proxy/setting/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /api/boss/proxy/setting/delete */
export async function postApiBossProxySettingDelete(
  body: platform.BatchDtolong,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponse>(`./api/boss/proxy/setting/delete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 编辑 POST /api/boss/proxy/setting/edit */
export async function postApiBossProxySettingEdit(
  body: platform.ProxySettingEditDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponselong>(`./api/boss/proxy/setting/edit`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /api/boss/proxy/setting/find/${param0} */
export async function getApiBossProxySettingFindById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.getApiBossProxySettingFindByIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<platform.JsonResponseProxySettingVo>(`./api/boss/proxy/setting/find/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 所有 POST /api/boss/proxy/setting/find/all */
export async function postApiBossProxySettingFindAll(
  body: platform.ProxySettingPageDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponseListProxySettingPageVo>(`./api/boss/proxy/setting/find/all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
