// @ts-ignore
/* eslint-disable */
// API 更新时间：
// API 唯一标识：
import * as dashboardAction from './dashboardAction';
import * as dictAction from './dictAction';
import * as enumAction from './enumAction';
import * as invitationCodeChangeHistoryAction from './invitationCodeChangeHistoryAction';
import * as invitationCodeAction from './invitationCodeAction';
import * as proxySettingAction from './proxySettingAction';
import * as resourceAction from './resourceAction';
import * as topicContentAction from './topicContentAction';
import * as topicAction from './topicAction';
import * as uploadAction from './uploadAction';
import * as userAction from './userAction';
import * as wxAction from './wxAction';
export default {
  dashboardAction,
  dictAction,
  enumAction,
  invitationCodeChangeHistoryAction,
  invitationCodeAction,
  proxySettingAction,
  resourceAction,
  topicContentAction,
  topicAction,
  uploadAction,
  userAction,
  wxAction,
};
