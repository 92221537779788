// @ts-ignore
/* eslint-disable */
import { request } from '../sdk';

/** 变更次数 POST /api/boss/invitation/code/change/time */
export async function postApiBossInvitationCodeChangeTime(
  body: platform.InvitationCodeChangeTimeDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponse>(`./api/boss/invitation/code/change/time`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /api/boss/invitation/code/find/${param0} */
export async function getApiBossInvitationCodeFindById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.getApiBossInvitationCodeFindByIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<platform.JsonResponseInvitationCodeVo>(
    `./api/boss/invitation/code/find/${param0}`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 详情 GET /api/invitation/code/info */
export async function getApiInvitationCodeInfo(options?: { [key: string]: any }) {
  return request<platform.JsonResponseInvitationCodeVo>(`./api/invitation/code/info`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 邀请码 GET /api/invitation/code/info/${param0} */
export async function getApiInvitationCodeInfoByCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.getApiInvitationCodeInfoByCodeParams,
  options?: { [key: string]: any },
) {
  const { code: param0, ...queryParams } = params;
  return request<platform.JsonResponseInvitationCodeVo>(`./api/invitation/code/info/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 使用 POST /api/invitation/code/use */
export async function postApiInvitationCodeUse(
  body: platform.InvitationCodeUseDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponseInvitationCodeUseVo>(`./api/invitation/code/use`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
