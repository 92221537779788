// @ts-ignore
/* eslint-disable */
import { request } from '../sdk';

/** 分页 POST /api/boss/invitation/code/change/history/find */
export async function postApiBossInvitationCodeChangeHistoryFind(
  body: platform.InvitationCodeChangeHistoryPageDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponsePageInvitationCodeChangeHistoryPageVo>(
    `./api/boss/invitation/code/change/history/find`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** 分页 POST /api/invitation/code/change/history/find */
export async function postApiInvitationCodeChangeHistoryFind(
  body: platform.InvitationCodeChangeHistoryPageDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponsePageInvitationCodeChangeHistoryPageVo>(
    `./api/invitation/code/change/history/find`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}
