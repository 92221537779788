// @ts-ignore
/* eslint-disable */
import { request } from '../sdk';

/** 详情 GET /api/boss/topic/find/${param0} */
export async function getApiBossTopicFindById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.getApiBossTopicFindByIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<platform.JsonResponseTopicVo>(`./api/boss/topic/find/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 所有 POST /api/boss/topic/find/all */
export async function postApiBossTopicFindAll(
  body: platform.TopicPageDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponseListTopicPageVo>(`./api/boss/topic/find/all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 新增 POST /api/topic/add */
export async function postApiTopicAdd(
  body: platform.TopicAddDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponselong>(`./api/topic/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /api/topic/delete */
export async function postApiTopicDelete(
  body: platform.BatchDtolong,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponse>(`./api/topic/delete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 编辑 POST /api/topic/edit */
export async function postApiTopicEdit(
  body: platform.TopicEditDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponselong>(`./api/topic/edit`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /api/topic/find/${param0} */
export async function getApiTopicFindById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.getApiTopicFindByIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<platform.JsonResponseTopicVo>(`./api/topic/find/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 所有 POST /api/topic/find/all */
export async function postApiTopicFindAll(
  body: platform.TopicPageDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponseListTopicPageVo>(`./api/topic/find/all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
