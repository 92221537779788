// @ts-ignore
/* eslint-disable */
import { request } from '../sdk';

/** 上传 GET /api/resource/&#42;&#42;/&#42; */
export async function getApiResource(options?: { [key: string]: any }) {
  return request<any>(`./api/resource/**/*`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 删除 GET /api/resource/delete/&#42;&#42;/&#42; */
export async function getApiResourceDelete(options?: { [key: string]: any }) {
  return request<platform.JsonResponse>(`./api/resource/delete/**/*`, {
    method: 'GET',
    ...(options || {}),
  });
}
