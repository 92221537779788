// @ts-ignore
/* eslint-disable */
import { request } from '../sdk';

/** index GET /api/wx */
export async function getApiWx(options?: { [key: string]: any }) {
  return request<any>(`./api/wx`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** index PUT /api/wx */
export async function putApiWx(options?: { [key: string]: any }) {
  return request<any>(`./api/wx`, {
    method: 'PUT',
    ...(options || {}),
  });
}

/** index POST /api/wx */
export async function postApiWx(options?: { [key: string]: any }) {
  return request<any>(`./api/wx`, {
    method: 'POST',
    ...(options || {}),
  });
}

/** index DELETE /api/wx */
export async function deleteApiWx(options?: { [key: string]: any }) {
  return request<any>(`./api/wx`, {
    method: 'DELETE',
    ...(options || {}),
  });
}

/** index PATCH /api/wx */
export async function patchApiWx(options?: { [key: string]: any }) {
  return request<any>(`./api/wx`, {
    method: 'PATCH',
    ...(options || {}),
  });
}

/** auth GET /api/wx/auth */
export async function getApiWxAuth(options?: { [key: string]: any }) {
  return request<any>(`./api/wx/auth`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** auth PUT /api/wx/auth */
export async function putApiWxAuth(options?: { [key: string]: any }) {
  return request<any>(`./api/wx/auth`, {
    method: 'PUT',
    ...(options || {}),
  });
}

/** auth POST /api/wx/auth */
export async function postApiWxAuth(options?: { [key: string]: any }) {
  return request<any>(`./api/wx/auth`, {
    method: 'POST',
    ...(options || {}),
  });
}

/** auth DELETE /api/wx/auth */
export async function deleteApiWxAuth(options?: { [key: string]: any }) {
  return request<any>(`./api/wx/auth`, {
    method: 'DELETE',
    ...(options || {}),
  });
}

/** auth PATCH /api/wx/auth */
export async function patchApiWxAuth(options?: { [key: string]: any }) {
  return request<any>(`./api/wx/auth`, {
    method: 'PATCH',
    ...(options || {}),
  });
}

/** authResult GET /api/wx/auth/result */
export async function getApiWxAuthResult(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.getApiWxAuthResultParams,
  options?: { [key: string]: any },
) {
  return request<any>(`./api/wx/auth/result`, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** authResult PUT /api/wx/auth/result */
export async function putApiWxAuthResult(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.putApiWxAuthResultParams,
  options?: { [key: string]: any },
) {
  return request<any>(`./api/wx/auth/result`, {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** authResult POST /api/wx/auth/result */
export async function postApiWxAuthResult(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.postApiWxAuthResultParams,
  options?: { [key: string]: any },
) {
  return request<any>(`./api/wx/auth/result`, {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** authResult DELETE /api/wx/auth/result */
export async function deleteApiWxAuthResult(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.deleteApiWxAuthResultParams,
  options?: { [key: string]: any },
) {
  return request<any>(`./api/wx/auth/result`, {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** authResult PATCH /api/wx/auth/result */
export async function patchApiWxAuthResult(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.patchApiWxAuthResultParams,
  options?: { [key: string]: any },
) {
  return request<any>(`./api/wx/auth/result`, {
    method: 'PATCH',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** init GET /api/wx/jsapi */
export async function getApiWxJsapi(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.getApiWxJsapiParams,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponseWxJsapiSignature>(`./api/wx/jsapi`, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
