// @ts-ignore
/* eslint-disable */
import { request } from '../sdk';

/** 所有 POST /api/dict/all */
export async function postApiDictAll(body: platform.DictPageDto, options?: { [key: string]: any }) {
  return request<platform.JsonResponseListDictPageVo>(`./api/dict/all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /api/dict/find/${param0} */
export async function getApiDictFindById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.getApiDictFindByIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<platform.JsonResponseDictVo>(`./api/dict/find/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
