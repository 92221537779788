// @ts-ignore
/* eslint-disable */
import { request } from '../sdk';

/** 上传 POST /api/upload */
export async function postApiUpload(body: string, options?: { [key: string]: any }) {
  return request<platform.JsonResponsestring>(`./api/upload`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取上传凭证 GET /api/upload/cloud */
export async function getApiUploadCloud(options?: { [key: string]: any }) {
  return request<platform.JsonResponseUploadVo>(`./api/upload/cloud`, {
    method: 'GET',
    ...(options || {}),
  });
}
