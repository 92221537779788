const api = {
  token: window.localStorage.getItem("token2"),
  baseURL: import.meta.env.VITE_API_BASE_URL,
  setToken(token: string) {
    api.token = token;
  },
};

export async function request<T>(url: string, config: any): Promise<T> {
  if (config.params) {
    url += "?";
    for (const key in config.params) {
      url += `${key}=${encodeURIComponent(config.params[key])}&`;
    }
  }
  const resp = await uni.request({
    url: url,
    header: { token: api.token, ...config.headers },
    ...config,
  });
  const data: any = resp.data;
  switch (data.result) {
    case "000009": 
    case "000002": {
      uni.removeStorageSync(import.meta.env.VITE_TOKEN_NAME);
      throw new Error(`登录超时`);
    }
    default: {
      if (data.error) {
        uni.showToast({
          title: data.error,
          icon: "none",
          duration: 2000,
        });
        throw new Error(data.error);
      }
    }
  }
  return data;
}
export default api;
