import { createSSRApp } from "vue";
import App from "./App.vue";
import {createPinia} from 'pinia'
import vant from 'vant'
import 'vant/lib/index.css'
import 'virtual:uno.css'
import { showToast } from 'vant';

window.toast = (msg) => showToast({ message: msg } )

export function createApp() {
  const app = createSSRApp(App);
  const pinia = createPinia()
  app.use(pinia)
  app.use(vant)
  return {
    app,
  };
}
