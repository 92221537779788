// @ts-ignore
/* eslint-disable */
import { request } from '../sdk';

/** 所有 POST /api/boss/topic/content/find/all */
export async function postApiBossTopicContentFindAll(
  body: platform.TopicContentPageDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponseListTopicContentPageVo>(`./api/boss/topic/content/find/all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 新增 POST /api/topic/content/add */
export async function postApiTopicContentAdd(
  body: platform.TopicContentAddDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponselong>(`./api/topic/content/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /api/topic/content/delete */
export async function postApiTopicContentDelete(
  body: platform.BatchDtolong,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponse>(`./api/topic/content/delete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 所有 POST /api/topic/content/find/all */
export async function postApiTopicContentFindAll(
  body: platform.TopicContentPageDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponseListTopicContentPageVo>(`./api/topic/content/find/all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
