// @ts-ignore
/* eslint-disable */
import { request } from '../sdk';

/** 图表 GET /api/boss/stat/chart */
export async function getApiBossStatChart(options?: { [key: string]: any }) {
  return request<platform.JsonResponseListMapstringobject>(`./api/boss/stat/chart`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 统计数量 GET /api/boss/stat/count */
export async function getApiBossStatCount(options?: { [key: string]: any }) {
  return request<platform.JsonResponseListMapstringobject>(`./api/boss/stat/count`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 图表 GET /api/dashboard/stat/chart */
export async function getApiDashboardStatChart(options?: { [key: string]: any }) {
  return request<platform.JsonResponseListMapstringobject>(`./api/dashboard/stat/chart`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 统计数量 GET /api/dashboard/stat/count */
export async function getApiDashboardStatCount(options?: { [key: string]: any }) {
  return request<platform.JsonResponseListMapstringobject>(`./api/dashboard/stat/count`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 图表 GET /api/dashboard/topic/stat/chart */
export async function getApiDashboardTopicStatChart(options?: { [key: string]: any }) {
  return request<platform.JsonResponseListMapstringobject>(`./api/dashboard/topic/stat/chart`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 统计数量 GET /api/dashboard/topic/stat/count */
export async function getApiDashboardTopicStatCount(options?: { [key: string]: any }) {
  return request<platform.JsonResponseListMapstringobject>(`./api/dashboard/topic/stat/count`, {
    method: 'GET',
    ...(options || {}),
  });
}
