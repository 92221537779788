import { defineStore } from "pinia";
import api from "@/api";

export default defineStore("user", {
    state() {
        return {
            user: {} as platform.UserVo,
            code: {} as platform.InvitationCodeVo,
        };
    },
    actions: {
        /**
         *
         */
        async auth() {
            const token = uni.getStorageSync("token2");
            if (!token) {
                window.location.href = `/api/wx/auth`;
                return;
            }
            api.setToken(token);
        },

        /**
         *
         */
        async me() {
            const { data: me } = await api.userAction.getApiUserMe();
            this.user = me || {};
            const { data } = await api.invitationCodeAction.getApiInvitationCodeInfo();
            this.code = data || {};
        },

        /**
         *
         */
        async init() {
            const { data } = await api.wxAction.getApiWxJsapi({ url: window.location.href.split("#")[0] });
            window.jWeixin.config({
                ...data,
                jsApiList: ["startRecord", "stopRecord", "scanQRCode", "checkJsApi", "startRecord", "stopRecord", "onVoiceRecordEnd", "translateVoice"],
            });
        },
    },
});
