import api from "@/api";
import Socket from "@/util/socket";
import { defineStore } from "pinia";

export default defineStore("topic", {
    state() {
        return {
            topicList: [] as platform.TopicPageVo[],
            topicContentList: [] as (platform.TopicContentPageVo & { isWait: boolean })[],
            topic: {} as platform.TopicVo,
            socket: new Socket(),
            socketConnect: false,
            lastContent: {} as platform.TopicContentAddDto,
            isWait: false,
            refreshContent: null as platform.TopicContentPageVo | null,
        };
    },
    actions: {
        /**
         *
         */
        async refreshTopicList() {
            const { data } = await api.topicAction.postApiTopicFindAll({});
            this.topicList = data || [];
            if (this.topicList.length > 0) {
                this.initTopic(this.topicList[0]?.id);
            } else {
                this.newTopic();
            }
        },

        /**
         *
         */
        async initTopic(id: number) {
            const { data: user } = await api.userAction.getApiUserMe();
            const { data } = await api.topicAction.getApiTopicFindById({ id });
            this.topic = data || {};
            this.refreshTopicContentList();
            this.socket.init(
                user.id,
                id,
                () => {
                    this.socketConnect = true;
                    console.log("websocket连接成功");
                },
                async (msg) => {
                    // 刷新问题
                    if (this.refreshContent) {
                        if (msg.data == "[DONE]") {
                            return;
                        }
                        let json_data = JSON.parse(msg.data);
                        this.refreshContent.ask += json_data.content || "";
                        this.refreshContent.isWait = false;
                    }
                    // 新的问题
                    else {
                        if (msg.data == "[DONE]") {
                            this.lastContent.askTime = new Date();
                            if (this.lastContent.ask) {
                                await api.topicContentAction.postApiTopicContentAdd(this.lastContent);
                            }
                            this.lastContent.id = Date.now();
                            this.topicContentList.push(this.lastContent);
                            this.lastContent = {};
                            this.isWait = false;
                            return;
                        }
                        let json_data = JSON.parse(msg.data);
                        this.lastContent.ask += json_data.content || "";
                        this.lastContent.askTime = new Date();
                        this.isWait = false;
                    }
                }
            );
        },

        /**
         *
         */
        async newTopic() {
            const { data } = await api.topicAction.postApiTopicAdd({ name: "新话题" });
            this.initTopic(data);
        },

        /**
         *
         * @param id
         */
        async deleteTopic(id: number) {
            await api.topicAction.postApiTopicDelete({ idList: [id] });
            this.refreshTopicList();
        },

        /**
         *
         * @param topic
         */
        async updateTopic(topic: platform.TopicVo) {
            await api.topicAction.postApiTopicEdit(topic);
        },

        ////////////////////////////////////
        /**
         *
         */
        async refreshTopicContentList() {
            const { data } = await api.topicContentAction.postApiTopicContentFindAll({ topicId: this.topic.id });
            this.topicContentList = (data || []).map((v) => {
                return {
                    ...v,
                    isWait: false,
                };
            });
        },

        /**
         *
         * @param o
         */
        async refreshAsk(o: platform.TopicContentPageVo) {
            this.refreshContent = o;
            this.refreshContent.ask = "";
            this.refreshContent.isWait = true;
            this.socket.send("refreshContent:" + o.id);
        },

        /**
         * 发送消息
         * @param keyword
         */
        async send(keyword: string) {
            this.refreshContent = null;
            this.isWait = true;
            this.lastContent.ask = "";
            this.lastContent.topicId = this.topic.id;
            this.lastContent.askTime = new Date();
            this.lastContent.question = keyword;
            this.lastContent.questionTime = new Date();
            console.log(`发送消息`, keyword);
            this.socket.send(keyword);
        },
    },
});
