// @ts-ignore
/* eslint-disable */
import { request } from '../sdk';

/** 编辑 POST /api/boss/user/edit */
export async function postApiBossUserEdit(
  body: platform.UserEditDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponselong>(`./api/boss/user/edit`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 分页 POST /api/boss/user/find */
export async function postApiBossUserFind(
  body: platform.UserPageDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponsePageUserPageVo>(`./api/boss/user/find`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /api/boss/user/find/${param0} */
export async function getApiBossUserFindById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: platform.getApiBossUserFindByIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<platform.JsonResponseUserVo>(`./api/boss/user/find/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 绑定 POST /api/user/bind */
export async function postApiUserBind(
  body: platform.UserBindDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponse>(`./api/user/bind`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 绑定-发送短信 POST /api/user/bind/send/sms */
export async function postApiUserBindSendSms(
  body: platform.UserBindPhoneSendSmsDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponse>(`./api/user/bind/send/sms`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 分页 POST /api/user/find/invite */
export async function postApiUserFindInvite(
  body: platform.UserPageDto,
  options?: { [key: string]: any },
) {
  return request<platform.JsonResponsePageUserPageVo>(`./api/user/find/invite`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /api/user/me */
export async function getApiUserMe(options?: { [key: string]: any }) {
  return request<platform.JsonResponseUserVo>(`./api/user/me`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 设置被邀请人的标签 POST /api/user/tag */
export async function postApiUserTag(body: platform.UserTagDto, options?: { [key: string]: any }) {
  return request<platform.JsonResponse>(`./api/user/tag`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
